// Colours
$white: #ffffff;
$grey-100: #F6F7F8;
$grey-200: #EFF2F5;
$grey-300: #E4E7EC;
$grey-400: #D6DADF;
$grey-500: #C3C7D1;
$grey-600: #A6ABB5;
$grey-700: #7D8691;
$grey-800: #5A5E6B;
$dark: #323842; 
$darker: #1F242C;
$darkest: #10141B;
$black: #090A10;

$pink: #FF7878; 



$blue-dark: #2270EB;
$blue: #73A8FD;
$blue-light: #BAE2FF;
$green-dark: #168E4E;
$green: #6ED29D;
$green-light: #CAFFE3;
$red-dark: #9F1425;
$red: #FF324B;
$red-light: #FFA6A6;
$orange-dark: #DB3E14;
$orange: #FF744F;
$orange-light: #FFB29D;
$yellow-dark: #FF9F1C;
$yellow: #FFBE0B;
$yellow-light: #FFE7A5;
$purple-dark: #3A076E;
$purple: #915EC6;
$purple-light: #DFC0FF;

$shadow-colour: rgba($dark, 0.25);

:export {
    white: $white;
    grey1: $grey-100;
    grey2: $grey-200;
    grey3: $grey-300;
    grey4: $grey-400;
    grey5: $grey-500;
    grey6: $grey-600;
    grey7: $grey-700;
    grey8: $grey-800;
    dark: $dark;
    darker: $darker;
    darkest: $darkest;
    black: $black;

    pink: $pink;
}