@import '/src/sass/essentials';

.project {
    &__wrapper {
        position: relative;
        overflow: hidden;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $grey-100;
    }
    position: relative;
    width: 100%;
    max-width: rem(556);
    margin: 0 auto;
    &__circle {
        position: absolute;
        top: 0;
        left: 50%;
        border-radius: 50%;
        z-index: -1;
        height: 100%;
        width: 200vw;
    }
    &__section, &__outro {
        position: relative;
        padding-left: rem(20);
        padding-right: rem(20);
    }
    &__section {
        margin-bottom: rem(48);
        & > h2 {
            margin-bottom: rem(12);
            padding-top: rem(8);
        }
        & > h3 {
            margin-top: rem(24);
            margin-bottom: rem(8);
        }
        & > p {
            margin-bottom: rem(20);
        }
        & > iframe {
            
        }
        // &::before {
        //     content: '';
        //     position: absolute;
        //     width: 100vw;
        //     height: calc(100% + 50px);
        //     // background-color: aliceblue !important;
        //     z-index: -1;
        //     top: -1px;
        //     left: 50%;
        //     transform: translateX(calc(-50%)) skewY(2deg);
        // }
    }
    &__no_circle {
        &::before {
            content: none;
        }
    }
    &__outro {
        z-index: 10;
        padding-top: rem(40);
        padding-bottom: rem(32);
        max-width: rem(360);
        margin: 0 auto;
        padding: rem(32) 5.5555vw;
        text-align: center;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            border-radius: 50%;
            z-index: -1;
            width: 300vw;
            height: 225vw;
            transform: translateX(calc(-50%));
            background-color: $grey-200;
        }
    }
    &__nextProject {
        position: relative;
        z-index: 15;
    }
    &__pagination {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 2.2222vw;
        padding: 0 rem(16);
        z-index: 5;
        &__number {
            color: $white;
        }
        &__bar {
            height: 1px;
            width: rem(40);
            position: relative;
            margin: 0 rem(8);
            background-color: rgba($color: $white, $alpha: .3);
            & > span {
                height: 100%;
                position: absolute;
                background-color: $white;
            }
        }
    }
}

@media (min-width: 640px) {
    .project {
        max-width: rem(560);
        &__section, &__outro {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (min-width:880px) {
    .project {
        max-width: rem(700);
        &__outro {
            &::before {
                width: 225vw;
            }
        }
    }
}