@import '/src/sass/essentials';

.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    // padding: 2.2222vw 0;
    z-index: 30;
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 20%;
    }
}
