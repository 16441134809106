@import '/src/sass/essentials';

.Icon {
    min-height: rem(48);
    min-width: rem(48);
    max-height: rem(48);
    max-width: rem(48);
    padding: rem(12);
    z-index: 2;
    cursor: pointer;
    &__menu {
        width: rem(24);
        height: rem(24);
        padding: rem(4.5) rem(3);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        & > span {
            width: rem(18);
            height: 1px;
            border-radius: 1px;
            background-color: $dark;
            transition: .4s ease-in-out;
        }
        &_focus {
            // align-items: center;
            & > span {
                background-color: $dark;
                transition: .3s ease-in-out;
                &:nth-of-type(1) {
                    transform: rotate(45deg) translate(5.5px, 5.5px);
                }
                &:nth-of-type(2) {
                    transform: scaleX(0);
                    opacity: 0;
                }
                &:nth-of-type(3) {
                    transform: rotate(-45deg) translate(4.5px, -4.5px);
                }
            }
        }
    }
    &__grid {
        width: 100%;
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 8px);
        gap: 2px;
        justify-content: center;
        align-content: center;
        transition: .3s ease-in-out;
        & > span {
            width: 8px;
            height: 8px;
            border-radius: 1px;
            border: 1px solid $dark;
            transition: .4s ease-in-out;
        }
        &::before, &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 50%;
            width: 18px;
            height: 1px;
            background-color: $dark;
            border-radius: 2px;
            opacity: 0;
            transition: .2s ease-in-out;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(45deg) scaleX(0);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(135deg) scaleX(0);
        }
        &_small {
            grid-template-columns: repeat(4, 6px);
            gap: 2px;
            & > span {
                width: 6px;
                height: 6px;
            }
        }
        &_big {
            grid-template-columns: repeat(1, 12px);
            & > span {
                width: 12px;
                height: 12px;
            }
        }
        &_active {
            &_span {
                background-color: $dark;
            }
            &.Icon__grid {
                // transform: rotate(180deg);
                & > span {
                    transform: scale(.1);
                    opacity: 0;
                }
                &::before, &::after {
                    opacity: 1;
                }
                &::before {
                    transform: translate(-50%, -50%) rotate(45deg) scaleX(1);
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg) scaleX(1);
                }
                &_small {

                }
                &_big {

                }
            }
        }
    }
    &_no_pad {
        min-width: auto;
        min-height: auto;
        padding: 0;
    }
    &:hover & {
        &__menu {
            & > span {
                background-color: $darker;
                transition: .3s ease-in-out;
                &:nth-of-type(1) {
                    width: rem(16);
                    transform: translateX(-1px);
                }
                &:nth-of-type(2) {
                    width: rem(20);
                    transform: translateX(-1px);
                }
                &:nth-of-type(3) {
                    width: rem(12);
                    transform: translateX(-1px);
                }
            }
            &_focus {
                & > span {
                    background-color: $darker;
                    transition: .3s ease-in-out;
                    &:nth-of-type(1) {
                        width: rem(18);
                        transform: rotate(45deg) translate(5.5px, 5.5px);
                    }
                    &:nth-of-type(2) {
                        width: 0;
                    }
                    &:nth-of-type(3) {
                        width: rem(18);
                        transform: rotate(-45deg) translate(4.5px, -4.5px);

                    }
                }
            }
        }
        &__grid {
            &_active {
                &_span {
                    border-radius: 50%;
                }
            }
            transition: .2s ease-in-out;
        }
    }
}