// Typography
@mixin font-family($family) {
	font-family: $family, Arial, Helvetica, sans-serif;
}

// Sizing & Spacing
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}
@mixin min-size($width, $height: $width) {
	min-width: $width;
	min-height: $height;
}
@mixin max-size($width, $height: $width) {
	max-width: $width;
	max-height: $height;
}

@mixin absolute-pos($top, $right: $top, $bot: $right, $left: $bot) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bot;
	left: $left;
}

// Background
@mixin background-pos($pos, $repeat, $size) {
	background-position: $pos;
    background-repeat: $repeat;
    background-size: $size;
}

// Styling


// Selection
@mixin user-select($type) {
	-webkit-touch-callout: $type;
    -webkit-user-select: $type;
    -khtml-user-select: $type;
    -moz-user-select: $type;
    -ms-user-select: $type;
    user-select: $type;
}
@mixin tap-highlight($transparent) {
	-webkit-tap-highlight-color: $transparent;
	-webkit-tap-highlight-color: transparent;
}

@mixin selection($bg, $colour) {
	background-color: $bg;
    color: $colour;
}

// ANIMATION //
@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}