@import '/src/sass/_abstracts/mixins';

.panels {
    // display: grid;
    // grid-template-columns: repeat(5, 1fr);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    &_bg {
        z-index: 1;
    }
    & > div {
        &:nth-of-type(1) > div > span {
            &:nth-last-of-type(1) {
                animation-delay: .1s;
            }
            &:nth-last-of-type(2) {
                animation-delay: .6s;
            }
        }

        &:nth-of-type(2) > div > span {
            &:nth-last-of-type(1) {
                animation-delay: .25s;
            }
            &:nth-last-of-type(2) {
                animation-delay: .4s;
            }
        }

        &:nth-of-type(3) > div > span {
            &:nth-last-of-type(1) {
                animation-delay: .4s;
            }
            &:nth-last-of-type(2) {
                animation-delay: .25s;
            }
        }

        &:nth-of-type(4) > div > span {
            &:nth-last-of-type(1) {
                animation-delay: .55s;
            }
            &:nth-last-of-type(2) {
                
                animation-delay: .1s;
            }
        }

        &:nth-of-type(5) > div > span {
            &:nth-last-of-type(1) {
                animation-delay: .7s;
            }
            &:nth-last-of-type(2) {
                // animation-delay: .1s;
            }
        }
    }
}

// @media (min-width: rem(320)) {
//     .panels {
//         grid-template-columns: repeat(6, 1fr);
//     }
// }