@import '/src/sass/essentials';
@import '/src/sass/_abstracts/transitions';

.Email {
    margin-left: calc(20vw - #{rem(16)});
    text-align: left;
    display: flex;
    align-items: center;
    & > span {
        display: flex;
        width: rem(6);
        height: rem(6);
        background-color: $dark;
        border-radius: 2px;
        transform: translateX(-3px);
        margin-right: 6px;
    }
    & > h6 {
        font-size: rem(14);
    }
    &_mgt_10 {
        margin-top: rem(10);
    }
    &_mgl_none {
        margin-left: 0;
    }
}

@media (min-width: 640px) {
    .Email {
        
    }
}