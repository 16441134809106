@import '/src/sass/essentials';

.Home {
    width: 100%;
    position: relative;
    transition: .2s;
    z-index: 20;
    &__nav {
        &__wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        & > li {
            padding-left: calc((100% - #{rem(32)}) / 2);
        }
    }
}
