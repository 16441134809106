::selection { @include selection($dark, $white); }

html {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .app {
    @include size(100%);
    -webkit-font-smoothing: antialiased;
    text-shadow: rem(1) rem(1) rem(1) rgba(0,0,0,0.004);
    background-color: $white;
    color: $dark;
}

*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: system-ui;
    z-index: 1;
}

*:active, *:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
}

@media screen and (max-device-width: 480px){
    body{ -webkit-text-size-adjust: 100%; }
}