@import '/src/sass/essentials';

$top_spacing: 6.6666vw + #{rem(44)};

.ProjectIntro {
    position: relative;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(4.4444vw + rem(8)) rem(8) rem(8);
    max-width: rem(560);
    margin-bottom: rem(20);
    // padding: rem(20) 0;
    &__img {
        display: flex;
        width: 100%;
        height: calc(100% - 192px);
        max-width: rem(1920);
        padding: rem(8);
        background-color: $dark;
        border-radius: rem(6) rem(6) 0 0;
        border-radius: rem(9);
        box-shadow: 0 0 0 1px $grey-400;
        z-index: 6;
        & > span {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: rem(6);
            border: 1px solid $darkest;
            overflow: hidden;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        padding: rem(20);
        padding: rem(20) 0 rem(88);
        z-index: 3;
        background-color: $white;
        box-shadow: 0 0 0 1px $grey-400;
        margin-top: 1px;
        border-radius: rem(9);
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: rem(-12);
        //     width: 100vw;
        //     height: rem(1000);
        //     background-color: $white;
        //     z-index: -1;
        //     left: 50%;
        //     transform: translateX(calc(-50%)) skewY(2deg);
        // }
        & > h1 {
            margin: 0 rem(20);
        }
        &__desc {
            margin: 0 rem(12);
            margin-top: rem(20);
            padding: rem(12);
            background: $grey-100;
            border: 1px solid $grey-300;
            border-radius: rem(6);
        }
    }
    &__software {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        border-radius: rem(5);
        padding: rem(12);
        
        right: rem(26);
        bottom: rem(26);
        background-color: transparent;
        border: 1px solid transparent;
        & > a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 3px;
            pointer-events: none;
            filter: invert(1);
            &:last-of-type {
                margin-right: 0;
            }
            & > img, & > svg {
                // max-width: rem(20);
            }
        }
        &__details {
            position: absolute;
            display: flex;
            flex-direction: column;
            bottom: calc(100% + rem(12));
            right: 0;
            background-color: $darker;
            padding: rem(12) rem(16);
            border-radius: rem(5);
            // min-width: 200%;
            // max-width: rem(152);
            z-index: 20;
            pointer-events: none;
            opacity: 0;
            transition: .3s ease-in-out;
            & > span {
                color: $grey-200;
                white-space: nowrap;
            }
            &::before {
                content: '';
                height: rem(12);
                width: rem(12);
                position: absolute;
                top: calc(100% - #{rem(6)});
                right: rem(16);
                background-color: $darker;
                transform: rotate(45deg);
                border-radius: 2px;
            }
        }
        &:hover & {
            &__details {
                opacity: 1;
                transition: .2s ease-in-out;
            }
        }
    }
}

@media (min-width: 640px) {
    .ProjectIntro {
        padding: 4.4444vw 0;
        max-width: rem(600);
        margin-bottom: 0;
        &__img {
            position: relative;
            & > span {
                position: relative;
            }
        }
        &__content {
            padding: rem(20) rem(8);
        }
        &__software {
            right: rem(4);
            bottom: rem(4);
            background-color: rgba($dark, .9);
            border: 1px solid $darker;
            & > a {
                filter: invert(0);
            }
            &__details {
                right: calc(100% + rem(12));
                bottom: 0;
                &::before {
                    top: auto;
                    right: auto;
                    left: calc(100% - #{rem(6)});
                    bottom: rem(16);
                }
            }
        }   
    }
}

@media (min-width: 880px) {
    .ProjectIntro {
        max-width: rem(740);
    }
}

@media (min-width: 1020px) {
    .ProjectIntro {
        padding: rem(40) 0;
    }
}

@media (min-width: 1260px) {
    .ProjectIntro {
        padding: rem(40) 0 rem(20);
        margin-bottom: rem(20);
    }
}