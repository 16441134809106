@import '/src/sass/essentials';
@import '/src/sass/_abstracts/transitions';

.CardText {
    position: relative;
    padding: rem(16);
    margin: rem(20) 0 rem(20) rem(64);
    background-color: $grey-100;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        background: linear-gradient(to right, rgba($color: $grey-100, $alpha: 1) 0%,rgba($color: $grey-100, $alpha: 0) 100%);
    }
    &_grey2 {
        background-color: $grey-200;
        &:before {
            background: linear-gradient(to right, rgba($color: $grey-200, $alpha: 1) 0%,rgba($color: $grey-200, $alpha: 0) 100%);
        }
    }
}

@media (min-width: 640px) {
    .CardText { 
        margin: rem(24) 0 rem(24) rem(72);
    }
}
@media (min-width: 1200px) {
    .CardText { 
        margin: rem(24) 0 rem(24) rem(88);
    }
}