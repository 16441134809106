@import "/src/sass/_foundation/colours.module";

@keyframes transitionTop {
    0%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(80%);
    }
}
@keyframes transitionBot {
    0%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-80%);
    }
}

.panel {
    height: 100%;
    width: 100%;
    position: relative;
    pointer-events: none;
    &__top, &__bot {
        height: 100%;
        width: 100%;
        position: absolute;
        border-left: 0.5px solid $grey-200;
        border-right: 0.5px solid $grey-200;
        background: rgba($color: $white, $alpha: 1);
        transition: .4s ease-in-out;
        pointer-events: none;
    }
    &__top {
        top: 0;
        border-bottom: 0.5px solid $grey-200;
        border-radius: 0 0 6px 6px;
        &__transition {
            animation: transitionTop 2.8s cubic-bezier(.41,.03,.57,.81);
        }
    }
    &__bot {
        bottom: 0;
        border-top: 0.5px solid $grey-200;
        border-radius: 6px 6px 0 0;
        &__transition {
            animation: transitionBot 2.8s cubic-bezier(.41,.03,.57,.81);
        }
    }
    &_noBorder {
        border-bottom: 0 solid transparent;
        border-top: 0 solid transparent;
    }
}