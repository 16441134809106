@import '/src/sass/essentials';
@import '/src/sass/_abstracts/transitions';

.Logo {
    &__container {
        display: flex;
        justify-content: center;
        position: relative;
    }
    position: relative;
    height: rem(48);
    width: rem(48);
    padding: rem(8);
    // margin: 0 rem(12);
    // min-width: rem(32);
    // max-width: rem(36);
    opacity: .9;
    // position: fixed;
    @extend %trans-long;
    & > svg, & > img {
        @include size(100%);
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: 9.7778px;
        height: 23px;
        border-radius: 0 0 5px 5px;;
        background-color: $darker;
        transition: 1s ease-in-out;
    }
    &:hover {
        @extend %trans-short;
        opacity: 1;
    }
    &_short {
        &::before {
            height: 0;
        }
    }
}
