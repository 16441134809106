.f {
    display: flex;
    &-ac {
        align-items: center;
    }
    &-jc {
        justify-content: center;
    }
}

.z {
    &-1 { z-index: 1; }
    &-2 { z-index: 2; }
    &-3 { z-index: 3; }
    &-4 { z-index: 4; }
    &-5 { z-index: 5; }
    &-6 { z-index: 6; }
    &-7 { z-index: 7; }
    &-8 { z-index: 8; }
    &-9 { z-index: 9; }
    &-10 { z-index: 10; }
}
