@import './_essentials.scss';

.mySwiper {
    .swiper-horizontal > .swiper-pagination-progressbar,
    .swiper-pagination-progressbar.swiper-pagination-horizontal,
    .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
    .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
        width: 12.5%;
        height: 1px;
        left: auto;
        top: auto;
        bottom: 29px;
        right: 0;
        margin: 0 13.75%;
    }
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: $dark;
    }
    & .swiper {
        &-pagination {
            position: fixed;
            &-numbers {
                display: flex;
                justify-content: space-between;
                width: 40%;
                bottom: 20px;
                right: 0;
            }
            &-number {
                width: 50%;
                &-count {

                }
                &-total {

                }
            }
        }
        &-button {
            &-next, &-prev {
                display: none;
            }
        }
    }
}