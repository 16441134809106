@import '/src/sass/essentials';
@import '/src/sass/_abstracts/transitions';
@import 'src/sass/_foundation/panelWidth.module.scss';

.LineText {
    position: relative;
    text-align: left;
    margin: rem(20) 0 rem(20) rem(64);
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $dark;
        transform: translateX(-.5px);
    }
    & > p, & > h5 {
        padding: rem(6) 0 rem(6) rem(12);
    }
    &_noSpace {
        margin: rem(20) 0;
    }
}

@media (min-width: 640px) {
    .LineText { 
        margin: rem(24) rem(72);
        &_noSpace {
            margin: rem(24) 0;
        }
    }
}
@media (min-width: 1200px) {
    .LineText { 
        margin: rem(24) rem(88);
        &_noSpace {
            margin: rem(24) 0;
        }
    }
}