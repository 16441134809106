@import '/src/sass/essentials';

.NextProject {
    position: relative;
    & > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: rem(48);
        & > *:first-child {
            margin-bottom: rem(6)
        }
        & > * {
            color: $white;
        }
        & > h3, h4 {
            font-size: rem(32);
        }
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(calc(-50%));
        background-color: $darker;
        border-radius: 50%;
        width: 200vw;
        height: 200vw;
        // height: 100%;
        z-index: -1;
    }
}